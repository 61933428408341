.car-main{
height: 90vh;

.paper{
    height: 80vh;
    background-color: #def1e7;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        height: 75vh;
        width:80vw;
    }
}
}

