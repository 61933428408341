.contact-us-container {
   
    .banner-contact {
      position: relative;
      height: 60vh; 
      background-image: url('../../../utils/words-with-contact-us-business-concept-idea_352439-357.avif');
      background-size: cover;
      background-position: center;
      color: #dfd8bc;
      font-size: 4rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width:500px){
        font-size: 3rem;

      }
  
      .overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  
 
    // Animation
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  
    .banner {
      animation: fadeIn 1s ease-in-out forwards;
    }
    .shimmer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0)
        );
    }
   
  }
