.contact-info1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #daf1e6;

    .head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3 {
          color: red;
          margin-top: 3rem;
          margin-bottom: 2rem;
        }
        h1 {
          color: #112f2f;
          font-size: 3rem;
          margin-bottom: 2rem;
          @media(max-width:800px){
            font-size: 2rem;
          }
        }
        p {
          font-size: 1.5rem;
          width: 80vw;
          text-align: justify;
          @media(max-width:800px){
            font-size: 1rem;
            margin-bottom: 20px;
          }
        }
      }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      background-color: #f8f8f8;
      border-radius: 8px;
      padding: 2rem;
      margin: 2%;
      width: 300px;
      height: 200px;
      border: 2px dashed #112f2f;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      @media(max-width:460px){
      width: 300px;
      margin: 0;
      font-size: small;
      padding-right: 15px;
    
      }
      .icon {
        margin: 20px;
        font-size: 50px;
        color: green;
        @media (max-width:460px) {
          margin: 5px;
        }
      }
    }
  }