/* Reset and Normalize */
body, h1, h2, h3, p, ul, li {
    margin: 0;
    padding: 0;
}

body {
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

/* Global Styles */
.landing-page {
    background-color: #f8f8f8;
    color: #333;
}

/* Header Styles */

/* Hero Section Styles */
.hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 20px;
    background-color: #8dbdf0;
    color: #ffffff;
   
}

.hero-content {
    max-width: 700px;
    text-align: left;
    margin: 20px;
}

.hero h1 {
    font-size: 45px;
    margin-bottom: 20px;
}

.hero p {
    font-size: 22px;
    margin-bottom: 30px;
}

.hero .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
        color: #ffffff;
    }
}

.hero-image {
    flex: 1;
}

.hero-image img {
    width: 80%;
    border-radius: 10px;
    margin-left: 10%;
}

/* Services Section Styles */
.services {
    background-color: #fff;
    padding: 80px 20px;
}

.services-content {
 
    max-width: 1200px;
    margin: 0 auto;
}
.service-image{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img{
  width: 40%;
  }
}

.services h2 {
    font-size: 42px;
    margin-bottom: 40px;
    text-align: center;
    font-style: italic;
}

.service-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media (max-width:1300px){
        flex-wrap: wrap;;
    }
}

.service-card {
    flex: 0 0 30%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 20px;
    text-align: center;
}

.service-card img {
    width: 300px;
    border-radius: 5px;
    height: 200px;
    @media (max-width:450px){
        width: 300px;
    }
}

.service-card p {
    font-size: 18px;
    margin-top: 10px;
}

/* About Us Section Styles */
.about-us {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 20px;
    @media(max-width:975px){
        flex-direction: column;
    }
}

.about-us-content {
    max-width: 600px;
    // text-align: left;
    padding: 2%;
    p{
       text-align: justify;
       line-height: 1.3;
    }
}

.about-us h2 {
    font-size: 42px;
    margin-bottom: 20px;
    font-style: italic;

}

.about-us p {
    font-size: 18px;
    margin-bottom: 30px;
}

.about-us .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007BFF;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
}

.about-us-image {
    flex: 1;
}

.about-us-image img {
    width: 100%;
    border-radius: 10px;
    @media(max-width:975px){
      margin-top: 40px;
      width: 500px;
    }
    @media(max-width:510px){
        margin-top: 40px;
      width: 300px;
      }
}

/* Testimonials Section Styles */
.testimonials {
    background-color: #f8f8f8;
    padding: 80px 20px;
}

.testimonials-content {
    max-width: 1200px;
    margin: 0 auto;
}

.testimonials h2 {
    font-size: 42px;
    font-style: italic;

    margin-bottom: 40px;
    text-align: center;
}

.testimonial-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}

.testimonial-card {
    flex: 0 0 30%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out; 
    &:hover {
        transform: scale(1.1); /* Apply a transformation on hover, e.g., scale up by 10% */
      }
}

.testimonial-card img {
    // width: 100px;
    // height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
}

.testimonial-card p {
    font-size: 18px;
    margin-top: 10px;
}

.testimonial-card span {
    display: block;
    margin-top: 15px;
    font-style: italic;
    color: #777;
}

/* Contact Section Styles */
/* Contact Section Styles */
.contact {
    background-color: #f5f5f5;
    padding: 80px 0;
    text-align: center;
  }
  
  .contact h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact p {
    font-size: 1.1em;
    color: #666;
    max-width: 600px;
    margin: 0 auto 40px;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  
  /* Form Styles */

  .contact-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 5%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  input,
  textarea {
    @media(max-width:450px){
        width: 300px;
    }
    width: 400px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #3498db;
  }
  
  .error {
    color: #e74c3c;
    margin-top: 5px;
  }
  
  .button {
    text-align: center;
  }
  
  button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  
  /* Add additional styling as needed */
   

  
$primary-color: #20212e;
$secondary-color: #ffffff;

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 75px;

  .logo {
    margin-left: 30px; /* Adjusted for better spacing */
    img {
      width: 4rem;
      border-radius: 50%;
    }
  }

  .hamburger-icon {
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: $secondary-color;
    margin-right: 20px; /* Adjusted for better spacing */
  }

  .nav-links {
    display: flex;

    a {
      color: $secondary-color;
      text-decoration: none;
      margin-left: 20px;
      font-weight: bold;
      margin-right: 16px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .nav-links {
      display: none;
    }

    .hamburger-icon {
      display: block;
    }

    &.show-menu {
      .nav-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px; /* Adjusted for better spacing */
        left: 0;
        width: 100%;
        background-color: $primary-color; /* Match background color */
        padding: 10px;
        color: $secondary-color;

        a {
          margin: 10px 0;
          color: $secondary-color;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

  
  
  
  
  
  
  