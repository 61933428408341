.container1{
    padding: 3%;
    background-color: #efefef;
    main{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        h1{
            color: #89896a;
            font-size: 3rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            text-align: center;
            @media(max-width:600px){
            font-size: 2rem;
                
            }
            @media(max-width:700px){
                font-size: 2.5rem;
                    
                }
                @media(max-width:600px){
                    font-size: 1.5rem;
                        
                    }

        }
        h2{
            color: rgb(223, 118, 191);
            font-size: 2rem;
            @media(max-width:600px){
                font-size: 1.5rem;
                text-align: center;
                    
                }
              

        }
        p{
            width: 80%;
            margin-bottom: 2rem;
            font-size: 1.3rem;
            text-align: justify;
          

        }
    }
    .main-box{
        display: flex;
        width: 100%;
        .left-box{
            width: 60%;
            img{
                width: 100%;
            }
        }
        .right-box{
            width: 40%;
            padding: 2%;
            h1{
                color: #89896a;
                font-size: 2rem;
                margin-top: 2rem;
                margin-bottom: 2rem;
            text-align: left;
            @media(max-width:600px){
                font-size: 1.5rem;
                text-align: center;
                    
                }

            }
            p{
            font-size: 1.3rem;
            line-height: 1.5;
            text-align: justify;
            }
            .card-main{
                display: flex;
                margin-top: 5rem;
                .card{
                    display: flex;
                    background-color: #daf1e6;
                    padding: 1.5rem;
                    margin: 4px;
                    .left{
                        color: green;
                        .icon{
                            font-size: 2.5rem;
                            margin-right: 1rem;
                        }
                    }
                    p{
                    color: #89896a;
                  

                    }
                }
            }
        }
    }
}