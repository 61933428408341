.box_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #def1e7;
  padding-bottom: 8rem;
  .head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      color: red;
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
    h1 {
      color: #112f2f;
      font-size: 3rem;
      margin-bottom: 2rem;
      width: 80%;
      text-align: center;
      @media(max-width:800px){
        font-size: 2rem;
      }
    }
    p {
      font-size: 1.5rem;
      width: 80%;
      text-align: justify;
   
    }
  }
  .card-box-main {
    margin-top: 3rem;
    background-color: #def1e7;
    overflow: hidden;
    .cards-box {
      display: flex;
      justify-content: space-between;
      width: 90vw;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      @media (max-width:850px) {
        flex-direction: column;
      }
  .cards-box1,.cards-box2,.cards-box3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
      @media(max-width:582px){
        flex-direction: column;
      }
      .back-card {
        margin: 2rem;
        position: relative;
        z-index: 1;
        cursor: pointer;

        img {
          width: 24vw;
          height: 250px;
          border-radius: 4%;
          transition: transform 0.3s ease; 
          @media(max-width:582px){
           width: 100%;
          }
          @media (max-width:850px) {
            width: 50vw;
          }
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      .front-card {
    margin-left: 25%;
        background-color: rgb(225, 219, 219);
        border: 5px solid white;
        width: 200px;
        height: 150px;
       
        z-index: 2;
      
        border-radius: 4%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media(max-width:500px){
            margin-left: 15%;
        }
        .icon{
            font-size: 3rem;
        }
      
      }

      .back-card2 {
        margin: 2rem;
        position: relative;
        z-index: 1;
        cursor: pointer;
        
        img {
          border-radius: 4%;
          width: 24vw;
          @media(max-width:582px){
            width: 100%;
           }
           @media (max-width:850px) {
            width: 50vw;
          }
          height: 250px;
          transition: transform 0.3s ease; 
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      .front-card2 {
        margin-left: 20%;
        background-color: rgb(225, 219, 219);
        border: 5px solid white;
        height: 150px;
        width: 200px;
       
        z-index: 2;
       
        border-radius: 4%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media(max-width:500px){
            margin-left: 15%;
        }
        .icon{
            font-size: 3rem;
        }
     
      }
      .back-card3 {
        margin: 2rem;
        position: relative;
        z-index: 1;
        cursor: pointer;

        img {
            width: 24vw;
          border-radius: 4%;
          height: 280px;
          @media(max-width:582px){
            width: 100%;
           }
           @media (max-width:850px) {
            width: 50vw;
          }
          transition: transform 0.3s ease; 
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      .front-card3 {
        margin-left: 25%;
        background-color: rgb(225, 219, 219);
        border: 5px solid white;
        width: 200px;
        height: 150px;
    
        z-index: 2;
     
        border-radius: 4%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media(max-width:500px){
            margin-left: 15%;
        }
        .icon{
            font-size: 3rem;
        }
       
      }
    }
  }
}
