.main-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  .box {
    width: 80vw;
    // height: 80.5vh;
    background-color: white;
    box-shadow: rgba(67, 130, 55, 0.35) 0px 5px 15px;
    border-radius: 1.5%;
    display: flex;
    @media(max-width:1160px){
        flex-direction: column;
    }
    .left-box {
      width: 50%;
      // width: 60vh;
      @media(max-width:1160px){
      display: flex;
      width: 100%;
       
    }
    @media(max-width:1160px){
        display: flex;
         align-items: center;
         justify-content: center;
           
        }
      .up-img {
        img {
          // width: 70vh;
          width: 100%;
          height: 100%;
          border-radius: 1.5%;
        }
        @media(max-width:1160px){
          display: none;
            // width: 50%;
             
          }
      }
      .bottom-img {
      
        img {
          width: 100%;
          height: 350px;

          border-radius: 1.5%;
          @media(max-width:1160px){
          
            width: 460px;
             
          }
          @media(max-width:650px){
            width: 400px;
        }
        @media(max-width:520px){
            width: 300px;
            height: 200px;
        }
        
        }
      }
    }
    .right-box {
      width: 50%;
      padding: 2%;
      @media(max-width:1160px){
        width: 95%;
       
         
      }
      @media(max-width:650px){
        text-align: center;
    }
      h2 {
        color: red;
        margin-bottom: 2rem;
       
      }
      h1 {
        color: #112f2f;
        font-size: 2.5rem;
        margin-bottom: 1rem;
        @media(max-width:1298px){
        font-size: 2rem;

        }
      }
      p {
        font-size: 1.5rem;
        text-align: justify;
        @media(max-width:1298px){
            font-size: 1.4rem;
    
            }

      }
    }
  }
}
