.container-main {
  background-image: url("../../utils/HOME\ IMG\ .jpeg");
  height: 9 0vh;
  width: 100%;
  background-size: cover;

  .section {
    display: flex;
    flex-direction: column;
    @media (max-width: 1000px) {
     justify-content: center;
      align-items: center;
    }
    h1 {
      color: white;
      font-size: 4rem;
      margin-top: 50px;
      padding: 2%;
      @media (max-width: 600px) {
        font-size: 2rem;
        text-align: center;
      }
      @media (max-width: 1000px) {
        padding: 2rem;
      }
    }
    p {
      width: 50%;
      color: white;
      font-size: 1.5rem;
      text-align: justify;
      padding: 2.2%;
      font-style: italic;
      font-family:Georgia, 'Times New Roman', Times, serif;
      @media (max-width: 1000px) {
        width: 90%;
       
      }
    }
    button {
      background-color: #112f2f;
      width: 20%;
      height: 3rem;
      margin: 2.2%;
      @media (max-width: 600px) {
        width: 60%;
      }
    }
  }
}
.shimmer-effect {
  position: relative;
  overflow: hidden;
}

.shimmer-effect::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.5) 50%, transparent 100%);
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    left: -150%;
  }
  100% {
    left: 100%;
  }
}
