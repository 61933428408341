$primary-color: #112f2f;
$secondary-color: #ffffff;

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between; // Ensure space between logo and hamburger icon
  background-color: #112f2f;

  position: sticky;
  top: 0;
  z-index: 100;
  height: 75px;
  overflow-x: hidden;

  .logo {
    margin-left: 30px;

    img {
      width: 4rem;
      border-radius: 50%;
    }
  }

  .hamburger-icon {
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: $secondary-color;
    margin-right: -87px; /* Adjusted for better spacing */
  }

  .nav-links {
    display: flex;
    justify-content: center; // Center the navigation links
    align-items: center;
    color: #d4af37;
    font-weight: bold;

    Link {
      color: white;
      text-decoration: none;
      margin: 0 20px; // Adjust spacing between links
      &:hover {
        text-decoration: underline;
      }
    }

    .icon {
      margin-right: 30px;
    }
  }

  @media only screen and (max-width: 580px) {
    .nav-links {
      display: none;
    }

    .hamburger-icon {
      display: flex;
    }

    .nav-links.show-menu {
      display: flex;
      flex-direction: column;
      position: fixed; /* Change position to fixed */
      top: 75px; /* Adjusted for better spacing */
      left: 0;
      width: 100%;
      background-color: $primary-color; /* Match background color */
      padding: 10px;
      color: $secondary-color;
      z-index: 101; /* Ensure menu overlaps page content */
    }
  }
}

