.container1{
    padding: 3%;
    background-color: #efefef;
   
    .main-box{
        display: flex;
        width: 100%;
        @media(max-width:800px){
            flex-direction: column;
          
        }
        .left-box{
            width: 60%;
            @media(max-width:800px){
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
        .right-box{
            width: 40%;
            padding: 2%;
            @media(max-width:800px){
                width: 90%;
            }
            h3{
                color: red;
            }
            h1{
                color: #89896a;
                font-size: 2rem;
                margin-top: 1rem;
                margin-bottom: 2rem;
            }
            p{
            font-size: 1.3rem;

            }
            .card-main{
                display: flex;
                flex-direction: column;
                margin-top: 3rem;
                .card{
                    display: flex;
                    background-color: #daf1e6;
                    padding: 1.5rem;
                    margin: 4px;
                    .left{
                        color: green;
                        .icon{
                            font-size: 2.5rem;
                            margin-right: 1rem;
                        }
                    }
                    p{
                    color: #89896a;

                    }
                }
            }
        }
    }
}