.content-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:1400px) {
         flex-direction: column;
    }
    .images-section {
  
    margin-top: 5vh;
  width: 50%;
  height: 80vh;
  @media (max-width:1381px) {
    display: none;
}
        .img1 {
            grid-area: 2 / 1;
            position: relative;
            top: -115px;
            pointer-events: none;
            height: 50%;
            left: 2rem;
          
            @media (max-width:1381px){
              display: none;

        
            }

          &:hover {
            transform: scale(1.1);
          }
        }
     
      

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 24px;
          color: white;
          z-index: 2;
        }
      }
    

      .right-box{
        margin-left: 5%;
        width: 50%;
        text-align: justify;
        @media (max-width:1381px){
            width: 90%;
        }
        @media (max-width:1400px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: justify;
            
        }
        h2{
            text-align: center;
            color: red;
            margin-bottom: 2rem;
        }
        h1{
            color: #112f2f;
            font-size: 3rem;
            text-align: center;
            @media (max-width:600px) {
font-size: 2rem;
            }
        }
        p{
            font-size: 1.5rem;
            margin: 10px;
            text-align: justify;
            @media (max-width:600px) {
                font-size: 1rem;
                            }
                            @media (max-width:1381px){
                                width: 90%;
                            }
                          
        }
    }
  }


  .back{
    @media (max-width:1381px){
        display: none;

  
      }
    width: 380px;
    height: 380px;
    background: repeat left -17px top -22px/55px 55px
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35px 35px'><foreignObject width='35px' height='35px'><div xmlns='http://www.w3.org/1999/xhtml' style='color:white;font-size:35px'>✦</div></foreignObject></svg>"),
      repeating-linear-gradient(
        to right,
        transparent,
        transparent 50px,
        white 50px,
        white 55px
      ),
      repeating-linear-gradient(
        to bottom,
        transparent,
        transparent 50px,
        white 50px,
        white 55px
      ),
      linear-gradient(45deg, rgb(203, 242, 217), rgb(14, 62, 24));
    grid-area: 2 / 1;
    cursor: pointer;
  }
  
  .back:hover {
    background: repeating-linear-gradient(
        to right,
        transparent,
        transparent 50px,
        rgb(255 255 255 / 0.5) 50px,
        rgb(255 255 255 / 0.5) 55px
      ),
      repeating-linear-gradient(
        to bottom,
        transparent,
        transparent 50px,
        rgb(255 255 255 / 0.5) 50px,
        rgb(255 255 255 / 0.5) 55px
      ),
      linear-gradient(45deg, rgb(203, 242, 217), rgb(14, 62, 24));
    box-shadow: 10px 10px 20px green;
  }
  

  
  .img2{
    width: 400px;
    position: absolute;
    top: 35rem;
    left: 20rem;
    margin-top: 2rem;
    @media (max-width:1381px){
        display: none;

  
      }
  }
  
  h3 {
    font-family: courier new;
    color: #bbb;
  }
  

  
  @supports (-moz-appearance: none) {
    .back {
      
      background: repeat left -17px top -20px/55px 55px
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35px 35px'><foreignObject width='35px' height='35px'><div xmlns='http://www.w3.org/1999/xhtml' style='color:transparent;text-shadow: 0 0 white;font:35px serif'>✦</div></foreignObject></svg>"),
        repeating-linear-gradient(
          to right,
          transparent,
          transparent 50px,
          white 50px,
          white 55px
        ),
        repeating-linear-gradient(
          to bottom,
          transparent,
          transparent 50px,
          white 50px,
          white 55px
        ),
        linear-gradient(45deg, pink, skyblue);
    }
  }