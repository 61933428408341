.contact {
    background-color: #cfd4d2;
    padding: 80px 0;
    text-align: center;
    // background: radial-gradient(circle, rgba(80,158,158,1) 50%, rgba(142,143,143,1) 100%);
    background-size: cover;
    
  }
  
  .contact h2 {
    font-size: 3rem;
    color:#112f2f;
    margin-bottom: 20px;
    @media(max-width:800px){
        font-size: 2rem;
    }
  }
  
  .contact p {
    font-size: 1.4em;
    color: green;
    font-weight: bold;
    max-width: 800px;
    margin: 0 auto 40px;
    text-align: justify;
    @media(max-width:800px){
        font-size: 1rem;
        margin-bottom: 20px;
        padding: 4%;
      }
  }
  
  .contact-info {
    display: flex;
  
  

  }
  
  /* Form Styles */

  .contact-form {
    background-color: #def1e7;
    margin: 0 auto;
    padding: 5%;
    min-height: 32rem;
    min-height: 32rem;
    width: 60vw;
    box-shadow: rgba(130, 195, 144, 0.35) 0px 5px 15px;
    border: 1px solid #357e3c;

  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #112f2f;
  }
  
  input
  {
   
    width: 100%;

    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #357e3c;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    height: 40px;
    color: #112f2f;

  }
  textarea {
   
    width: 100%;

    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    height: 100px;
    border: 1px solid #357e3c;
    color: #112f2f;

  }
  
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #112f2f;
  }
  
  .error {
    color: #e74c3c;
    margin-top: 5px;
  }
  
  .button {
    text-align: center;
  }
  
  .submit{
    background-color: #112f2f;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  button:hover {
    background-color: #2b6161;
  }
  
 
   

  