

.about-us-container {

    .content-section {
       padding: 4rem;
       @media(max-width:568px){
        padding: 2rem;
       }
        .head{

       display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        h1{
            color: #112f2f;
            font-size: 3rem;
            margin-bottom: 3rem;
            @media (max-width:600px) {
                font-size: 2rem;
                            }
        }
    }
        p{
            font-size: 1.5rem;
        }
  
     
      .team-section {
        margin-top: 4rem;
        flex: 1;
        padding: 0 20px;
        @media (max-width:680px) {
            padding: 0;
        }

      }
  
      .team-member1 {
       
       background-color: #def1e7;
       padding: 2%;
       width: 50%;
       border-radius: 20px;
        display: flex;
       
        @media (max-width:650px) {
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
            
        }
        @media (max-width : 992px) {
            width: 100%;
             
            }
           
       .content{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        @media (max-width:650px) {
            
            justify-content: center;
            align-items: center;
          
      }
       }
       .left{
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       }
        
        h3 {
            margin-bottom: 5px;
            font-size: 100px;
            background-color: #112f2f;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width:600px) {
                font-size: 2rem;
                width: 100px;
                height: 100px;
                            }
        }
  
        p {
          color: #2f7a2f;
          margin-top: 10px;
          @media (max-width:650px) { 
            margin-left: 14px;
          }
          align-items: center;
          @media (max-width:600px) {
            font-size: 1rem;
         
           
                        }
        }
      }
      .team-member2 {
       background-color: rgb(238, 233, 233);
       margin: 2%;
       margin-left: 50%;
       padding: 2%;
       width: 50%;
       border-radius: 20px;
       display: flex;
       @media (max-width : 992px) {
       margin-left: 0;
       width: 100%;
        
       }
       @media (max-width:650px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      
  }
       .content{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        @media (max-width:650px) {
            
            justify-content: center;
            align-items: center;
          
      }
       }
       .left{
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       }
  
        h3 {
          margin-bottom: 5px;
          font-size: 100px;
          background-color: #112f2f;
          width: 140px;
          height: 140px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
         
          @media (max-width:600px) {
            font-size: 2rem;
            width: 100px;
            height: 100px;
                        }
        }
  
        p {
          color: #2f7a2f;
          @media (max-width:650px) { 
            margin-left: 14px;
          }
          @media (max-width:600px) {
            font-size: 1rem;
         
           
                        }

        }
      }
    }
  }
  