.main-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5rem;
    background-color: #def1e7;
    padding: 2%;
    border-radius: 3%;
    @media (max-width : 672px) {
    margin: 1rem;
       
         
        }
    img{
        width: 70rem;
        margin-top: 3rem;
        border-radius: 3%;
        @media (max-width : 1341px) {
            width: 50rem;
             
            }
            @media (max-width : 998px) {
                width: 30rem;
                 
                }
                @media (max-width : 672px) {
                    width: 20rem;
                     
                    }
    }
    h1{
        color: #112f2f;
        font-size: 3rem;
        margin-top: 1rem;
        @media (max-width:600px) {
            font-size: 2rem;
         
           
                        }
    }
    p{
        font-size: 1.5rem;
        text-align: justify;
        margin-top: 2rem;
        @media (max-width : 672px) {
        //   text-align: left;   
          width: 100%;
                }
                @media (max-width:600px) {
                    font-size: 1rem;
                  justify-content: center;
                   
                                }
        
    }
}