
body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
  }
  .content{
    p{
        text-align: justify;
    }
  }
  .services {
    padding: 50px 0;
    text-align: center;
  }
  
  .services h1 {
    font-size: 3rem;
    margin-bottom: 4%;
    color: #112f2f;
    margin-top: 5%;
    @media(max-width:800px){
        font-size: 2rem;
    }
  }
  
  .service-section {
    margin-bottom: 50px;
  
    h3 {
      font-size: 1.5rem;
      margin-bottom: 20px;
      color: green;
    }
  
    .service-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;
  
      .service-card {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transition: all 0.3s ease-in-out; /* Animate all properties on hover */
        cursor: pointer;
  
        /* Remove flip animation styles */
        perspective: none; /* Disable 3D perspective */
  
        .card-front,
        .card-back {
          position: relative; /* Remove absolute positioning */
          transform: none; /* Remove initial rotation */
        }
  
        &:hover {
          /* Add hover animation styles */
          transform: scale(1.1); /* Increase scale slightly on hover */
          /* Add other hover effects as desired (e.g., box-shadow, border) */
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }
  
        .icon-container {
          margin-bottom: 10px;
          font-size: 2rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  
  /* Media Queries (adjust as needed) */
  @media (max-width: 768px) {
    .service-grid {
        display: flex;
        justify-content: center;
        align-items: center;
      flex-direction: column;
    }
  
    .service-card {
        align-items: center;
      width: 80%;
    }
  }
  
  /* Optional - Add color variations for different service cards (modify as needed) */
  .service-card.it-recruitment {
    background-color: #f5f5f5;
    .icon-container {
      color: #007bff; /* Blue for IT recruitment */
    }
  }
  
  .service-card.software-development {
    background-color: #f8f9fa;
    .icon-container {
      color: #28a745; /* Green for software development */
    }
  }
  
  .service-card.network-security {
    background-color: #faf0f0;
    .icon-container {
      color: #dc3545;
    }
  }
  
  