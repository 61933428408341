.App {
  background-color: #112f2f;
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  .titles {
    width: 40vw;
    color: #dff1e7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1100px) {
      width: 60vw;
      margin-left: 8rem;
    }
    @media (max-width: 720px) {
      width: 30vw;
      margin-left: 0rem;
    }

    h3 {
      color: red;
      margin: 2rem;
      width: 100%;
    }
    .tag {
      display: flex;
      .icon {
        margin-top: 2rem;
        margin-left: 2rem;
        font-size: 2.5rem;
      }
      &:hover {
        color: #8bdeb0;
      }
    }
    h2 {
      margin: 2rem;

      cursor: pointer;
    }
  }
  .image-container {
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 2rem;

    img {
      width: 700px;
      border-radius: 2%;
      box-shadow: rgba(216, 211, 211, 0.35) 0px 5px 15px;
      @media (max-width: 720px) {
        width: 500px;
        margin-left: 0rem;
      }
      @media (max-width: 550px) {
        width: 300px;
        margin-left: 0rem;
      }
    }
  }
}
